export const SliderDataICC = [
  {
    image: "./images/ICC_Image1.jpeg",
  },

  {
    image: "./images/ICC_Image2.jpeg",
  },
  {
    image: "./images/ICC_Image3.jpeg",
  },
  {
    image: "./images/ICC_Image4.jpeg",
  },
  {
    image: "./images/ICC_Image5.jpeg",
  },
  {
    image: "./images/ICC_Image6.jpeg",
  },
];
