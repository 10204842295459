export const SliderDataGallery = [
  {
    image: "./images/Zline_Image3.jpeg",
  },

  {
    image: "./images/Zline_Image2.jpeg",
  },
  {
    image: "./images/Zline_Image1.jpeg",
  },
  {
    image: "./images/Zline_Image5.jpeg",
  },
  {
    image: "./images/Ngawha_Image1.jpeg",
  },

  {
    image: "./images/Ngawha_Image2.jpeg",
  },
  {
    image: "./images/Ngawha_Image3.jpeg",
  },
  {
    image: "./images/Ngawha_Image4.jpeg",
  },
  {
    image: "./images/Ngawha_Image5.jpeg",
  },
  {
    image: "./images/Ngawha_Image6.jpeg",
  },
  {
    image: "./images/Ngawha_Image7.jpeg",
  },
  {
    image: "./images/Ngawha_Image8.jpeg",
  },
  {
    image: "./images/Ngawha_Image9.jpeg",
  },
  {
    image: "./images/Ngawha_Image10.jpeg",
  },
  {
    image: "./images/Ngawha_Image12.jpeg",
  },
  {
    image: "./images/TeMihi_Image1.jpg",
  },

  {
    image: "./images/TeMihi_Image2.jpg",
  },
  {
    image: "./images/TeMihi_Image3.jpg",
  },
  {
    image: "./images/TeMihi_Image4.jpg",
  },
  {
    image: "./images/TeMihi_Image5.jpg",
  },
  {
    image: "./images/TeMihi_Image6.jpg",
  },
  {
    image: "./images/TeMihi_Image7.jpg",
  },
  {
    image: "./images/TeMihi_Image8.jpg",
  },
  {
    image: "./images/TeMihi_Image9.jpg",
  },
  {
    image: "./images/ICC_Image1.jpeg",
  },

  {
    image: "./images/ICC_Image2.jpeg",
  },
  {
    image: "./images/ICC_Image3.jpeg",
  },
  {
    image: "./images/ICC_Image4.jpeg",
  },
  {
    image: "./images/ICC_Image5.jpeg",
  },
  {
    image: "./images/ICC_Image6.jpeg",
  },
  {
    image: "./images/DTFB_Image1.jpeg",
  },

  {
    image: "./images/DTFB_Image2.jpeg",
  },
  {
    image: "./images/DTFB_Image3.jpeg",
  },
  {
    image: "./images/DTFB_Image4.jpeg",
  },
  {
    image: "./images/DTFB_Image5.jpeg",
  },
  {
    image: "./images/DTFB_Image6.jpeg",
  },
  {
    image: "./images/DTFB_Image7.jpeg",
  },
  {
    image: "./images/DTFB_Image8.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image1.jpeg",
  },

  {
    image: "./images/Tauhara2022_Image2.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image3.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image4.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image5.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image6.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image7.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image8.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image9.jpeg",
  },
  {
    image: "./images/TSP_Image3.jpeg",
  },
  {
    image: "./images/TSP_Image2.jpeg",
  },
  {
    image: "./images/TSP_Image1.jpeg",
  },
  {
    image: "./images/TSP_Image5.jpeg",
  },
  {
    image: "./images/TSP_Image9.jpeg",
  },
  {
    image: "./images/TSP_Image10.jpeg",
  },
  {
    image: "./images/TSP_Image11.jpeg",
  },
  {
    image: "./images/WeldQuality_Image3.jpeg",
  },
  {
    image: "./images/WeldQuality_Image4.jpeg",
  },
  {
    image: "./images/TSP_Image1.jpeg",
  },
  {
    image: "./images/TSP_Image4.jpeg",
  },
  {
    image: "./images/TSP_Image6.jpeg",
  },
  {
    image: "./images/TSP_Image7.jpeg",
  },
  {
    image: "./images/TSP_Image8.jpeg",
  },
  {
    image: "./images/TSP_Image9.jpeg",
  },
  {
    image: "./images/TSP_Image11.jpeg",
  },
  {
    image: "./images/TSP_Image12.jpeg",
  },
  {
    image: "./images/WeldQuality_Image4.jpeg",
  },
  {
    image: "./images/WeldQuality_Image5.jpeg",
  },
];
