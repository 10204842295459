export const SliderDataTaraSeparationTwo = [
  {
    image: "./images/TSP_Image1.jpeg",
  },
  {
    image: "./images/TSP_Image4.jpeg",
  },
  {
    image: "./images/TSP_Image6.jpeg",
  },
  {
    image: "./images/TSP_Image7.jpeg",
  },
  {
    image: "./images/TSP_Image8.jpeg",
  },
  {
    image: "./images/TSP_Image9.jpeg",
  },
  {
    image: "./images/TSP_Image11.jpeg",
  },
  {
    image: "./images/TSP_Image12.jpeg",
  },
  {
    image: "./images/WeldQuality_Image4.jpeg",
  },
  {
    image: "./images/WeldQuality_Image5.jpeg",
  },
];
