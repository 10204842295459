import React from "react";
import "../CardItemPages.css";
import CardItemPageTSPC from "../CardItemPageTSPC";
import "../../App.css";

function TSCPPage() {
  return (
    <div className="cardsbackground">
      {/* <img src="../../public/images/RS_Background_2.png" alt=""></img> */}
      <div className="cards">
        <h1>TAURANGA SOUTHERN PIPELINE CROSSING</h1>
        <br></br>
        <div className="cards__container">
          <div className="cards_wrapper">
            <ul className="cards__items">
              <CardItemPageTSPC
                src="images/TaurangaSP_Image3.jpeg"
                text="RS Contracting was employed to weld 900 diameter steel casings which ran through a bored hole from Matapihi to Memorial Park Tauranga."
                textthree="On the memorial park side we made x4 500 metre strings ready to be pulled through the hole towards Matapihi. The rig pulled the first string through the hole we then added on to the string with another 500 metre string and repeated the process until the start of the pipe emerged at Matapihi."
                textseven="500 Metre Strings."
                srctwo="images/TaurangaSPC_Image345.png"
                srcfour="images/TaurangaSCP_Image126.png"
                path="/PrevousProjectsPage"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TSCPPage;
