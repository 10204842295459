import React from "react";
import "../CardItemPages.css";
import ImageSliderGallery from "../ImageSliderGallery";
import { SliderDataGallery } from "../SliderDataGallery";
import "../../App.css";

function GalleryPage() {
  return (
    <div className="cardsbackground">
      <div className="cards">
        <div className="cards_wrapper">
          <h1>PHOTO GALLERY</h1>
          <ImageSliderGallery slides={SliderDataGallery} />
        </div>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default GalleryPage;
