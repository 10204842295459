import React from "react";
import "./CardItemPages.css";
import "./CardItemPageKawerau";
import "./CardItemPagesTeMihiTwo";
import CardItemPageKawerau from "./CardItemPageKawerau";
import ImageSlider from "./ImageSlider";
import ImageSliderICC from "./ImageSliderICC";
import { SliderData } from "./SliderData";
import { SliderDataICC } from "./SliderDataICC";
import CardItemPagesTeMihiTwo from "./CardItemPagesTeMihiTwo";
import CardItemPagesConventionCentre from "./CardItemPagesConventionCentre";
import CardItemPageTSPC from "./CardItemPageTSPC";
import "../App.css";

function PrevousJobsCard() {
  return (
    <div className="cardsbackground">
      <div className="cards">
        <h1 className="cardsh1">Z LINE - CONTACT ENERGY</h1>
        <br></br>
        <div className="cards__container">
          <div className="cards_wrapper">
            <ul className="cards__items">
              <CardItemPagesTeMihiTwo
                src="images/TeMihiPhoto.jpeg"
                text="Te Mihi steam field and pipe corridor Kawerau KA30 pipeline."
                texttwo=""
                path="/PrevousProjectsPage"
              />
            </ul>

            <br></br>
            <h1 className="cardsh1">KAWERAU KA30 PIPELINE</h1>
            <br></br>
            <div className="cards__container">
              <div className="cards_wrapper">
                <ul className="cards__items">
                  <CardItemPageKawerau
                    src="images/Kawerau30_Image1.jpeg"
                    textfivetitle="Kawerau KA30 Pipeline"
                    texttwo="- 750 &Oslash; Line production 2km."
                    textthree="- 600 &Oslash; Line production 2km."
                    textfour="- 400 &Oslash; Line water 1km."
                    textfive="RS Contracting spooled up 5km of pipeline for MB Century and also helped with insulation."
                    path="/PrevousProjectsPage"
                  />
                </ul>

                <br></br>
                <br></br>
                <h2 className="cardsh2">PHOTO GALLERY OF Z LINE PROJECT</h2>
                <br></br>
                <ImageSlider slides={SliderData} />
                <br></br>
                <h1 className="cardsh1">
                  INTERNATIONAL CONVENTION CENTRE AUCKLAND
                </h1>
                <br></br>
                <div className="cards__container">
                  <div className="cards_wrapper">
                    <ul className="cards__items">
                      <CardItemPagesConventionCentre
                        src="images/ICC_Image1.jpeg"
                        text="RS Contracting were contracted to fabricate 300 x 900 diameter and 600 diameter pipe columns for the main structure of the International Convention Centre. Columns were fabricated in Culham Engineering's yard in Whangarei and shipped down to Auckland for installation."
                        path="/PrevousProjectsPage"
                      />
                    </ul>
                    <br></br>
                    <br></br>
                    <h2 className="cardsh2">
                      PHOTO GALLERY OF THE INTERNATIONAL CONVENTION CENTRE
                    </h2>
                    <ImageSliderICC slides={SliderDataICC} />

                    <h1 className="cardsh1">
                      TAURANGA SOUTHERN PIPELINE CROSSING
                    </h1>
                    <br></br>

                    <div className="cards__container">
                      <div className="cards_wrapper">
                        <ul className="cards__items">
                          <CardItemPageTSPC
                            src="images/TaurangaSP_Image3.jpeg"
                            text="RS Contracting was employed to weld 900 diameter steel casings which ran through a bored hole from Matapihi to Memorial Park Tauranga."
                            textthree="On the memorial park side we made 4x 500 metre strings ready to be pulled through the hole towards Matapihi. The rig pulled the first string through the hole we then added on to the string with another 500 metre string and repeated the process until the start of the pipe emerged at Matapihi."
                            textfivetitle="500 Metre Strings"
                            srctwo="images/TaurangaSPC_Image345.png"
                            srcfour="images/TaurangaSCP_Image126.png"
                            path="/PrevousProjectsPage"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrevousJobsCard;
