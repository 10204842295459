export const SliderDataTaraSeparation = [
  {
    image: "./images/TSP_Image3.jpeg",
  },
  {
    image: "./images/TSP_Image2.jpeg",
  },
  {
    image: "./images/TSP_Image1.jpeg",
  },
  {
    image: "./images/TSP_Image5.jpeg",
  },
  {
    image: "./images/TSP_Image9.jpeg",
  },
  {
    image: "./images/TSP_Image10.jpeg",
  },
  {
    image: "./images/TSP_Image11.jpeg",
  },
  {
    image: "./images/WeldQuality_Image3.jpeg",
  },
  {
    image: "./images/WeldQuality_Image4.jpeg",
  },
];
