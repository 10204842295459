import React from "react";
import "./Form.css";
import { useState } from "react";
import { send } from "emailjs-com";

const FormSignup = () => {
  const [sender_name, set_sender_name] = useState("");
  const [sender_email, set_sender_email] = useState("");
  const [message, set_message] = useState("");

  const handleName = (e) => {
    set_sender_name(e.target.value);
  };
  const handleEmail = (e) => {
    set_sender_email(e.target.value);
  };
  const handlemessage = (e) => {
    set_message(e.target.value);
  };
  const sendMail = (e) => {
    e.preventDefault();
    send(
      "service_3r4pkvm",
      "template_405b55b",
      { sender_name, sender_email, message },
      "Qn5At6wYAhFnqGKok"
    )
      .then((response) => {
        console.log(
          "Message sent successfully",
          response.status,
          response.text
        );
      })
      .catch((err) => {
        console.log("Failed", err);
      });
    set_sender_name("");
    set_sender_email("");
    set_message("");
  };

  return (
    <div className="form-content-right">
      <form onSubmit={sendMail} className="form" noValidate>
        <img
          className="form-img-2"
          src="images/WelderOneDropshadow.png"
          alt=""
        />

        <h1>CONTACT</h1>
        <div className="form-inputs">
          <label className="form-label">Name</label>
          <input
            className="form-input"
            type="text"
            name="sender_name"
            value={sender_name}
            onChange={handleName}
            required
            placeholder="Your Name"
          />
        </div>
        <div className="form-inputs">
          <label className="form-label">Email</label>
          <input
            className="form-input"
            type="email"
            name="sender_email"
            value={sender_email}
            onChange={handleEmail}
            required
            placeholder="Your Email"
          />
        </div>
        <div className="form-messages">
          <label className="form-label">Message</label>
          <textarea
            className="form-message"
            name="message"
            value={message}
            onChange={handlemessage}
            required
            placeholder="Your Message"
          />
        </div>
        <div className="form-inputs"></div>
        <button className="form-input-btn" type="submit">
          Send Email
        </button>
      </form>
    </div>
  );
};

export default FormSignup;
