import React from "react";
import "../../App.css";
// import ContactUs from '../ContactUs';
import Form from "../Form";

function ContactPage() {
  return (
    <div className="cardsbackground">
      {/* <img src="../../public/images/RS_Background_2.png" alt=""></img> */}
      <div className="cards">
        <div>
          <Form />
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
