import React from "react";
import "../../App.css";
import Cards from "../Cards";
import MainPage from "../MainPage";

<meta name="google-site-verification" content="google3e477eb987fc71e0.html" />;

function Home() {
  return (
    <>
      <MainPage />
      <Cards />
    </>
  );
}

export default Home;
