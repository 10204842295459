import React from "react";
import CardItemPages from "./CardItemPages";
import "./CardItemPages.css";
import CardItemPagesTeMihi from "./CardItemPagesTeMihi";
import CardItemPagesNgawha from "./CardItemPagesNgawha";
import ImageSlider from "./ImageSlider";
import ImageSliderTeMihi from "./ImageSliderTeMihi";
import { SliderData } from "./SliderData";
import ImageSliderNgawha from "./ImageSliderNgawha";

function ProjectCards() {
  return (
    <div className="cardsbackground">
      <div className="cards">
        <h1 className="cardsh1">Z LINE - CONTACT ENERGY</h1>
        <br></br>
        <div className="cards__container">
          <div className="cards_wrapper">
            <ul className="cards__items">
              <CardItemPages
                src="images/ZLine_Image4.jpeg"
                text="8km of 750 &Oslash; 15.88 wall reinjection pipeline, connecting Te Mihi pumping station to wells:"
                texttwo="- wk 404"
                textthree="- wk 401"
                textfour="- wk 410"
                textfive="- wk 409"
                textsix="- wk 406"
                textseven="- wk 403"
                texteight="Click here to see more pictures"
                path="/ProjectsPage"
              />
            </ul>
            <br></br>
            <br></br>
            <h2 className="cardsh2">PHOTO GALLERY OF Z LINE PROJECT</h2>
            <ImageSlider slides={SliderData} />
            <h1 className="cardsh1">TE MIHI - CLIENTS CONTACT ENERGY</h1>
            <br></br>
            <br></br>
            <ul className="cards__items">
              <CardItemPagesTeMihi
                src="images/TeMihi_Image1.jpg"
                text="RS Contracting worked under Warner Construction to spooled up 10km of 1050 std + x5 pipe. Managed area 55 tie in's, this was a three week shut down tying in new piping to the current system."
                textthree="Installed both seperation plant pipe work."
                srctwo="images/TeMihi_Images789.png"
                textfivetitle="Rock Mufler"
                textfive="Steam field to power station tie in Rock Mufler."
                srcthree="images/TeMihi_Images23456.png"
                path="/ProjectsPage"
              />
            </ul>
            <br></br>
            <h2 className="cardsh2">PHOTO GALLERY OF TE MIHI PROJECT</h2>
            <ImageSliderTeMihi slides={SliderData} />
            <br></br>
            <h1 className="cardsh1">NGAWHA TOP ENERGY</h1>
            <br></br>
            <ul className="cards__items">
              <CardItemPagesNgawha
                src="images/Ngawha_Image1.jpeg"
                text="RS Contracting subcontracted to Culham Engineering to spool up:"
                texttwo="- 2.5km 500 xxs reinjection."
                textthree="- 1.5km 750 STD weight production."
                textfour="- 1.5km 700 STD weight production."
                srctwo="images/Ngawha_Image91011.png"
                textfivetitle="Insulation"
                textsix="Once spooled up RS Contracting installed 2.5km of 500 xxs piping to the reinjection wells."
                srcthree="images/Ngawha_Imga13458.png"
                textseven="Installed 1500 blow down piping."
                srcfour="images/Ngawha_Image26712.png"
                path="/ProjectsPage"
              />
            </ul>
            <br></br>
            <h2 className="cardsh2">PHOTO GALLERY OF NGAWHA PROJECT</h2>
            <ImageSliderNgawha slides={SliderData} />
            <br></br>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCards;
