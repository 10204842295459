export const SliderDataFerryBassin = [
  {
    image: "./images/DTFB_Image1.jpeg",
  },

  {
    image: "./images/DTFB_Image2.jpeg",
  },
  {
    image: "./images/DTFB_Image3.jpeg",
  },
  {
    image: "./images/DTFB_Image4.jpeg",
  },
  {
    image: "./images/DTFB_Image5.jpeg",
  },
  {
    image: "./images/DTFB_Image6.jpeg",
  },
  {
    image: "./images/DTFB_Image7.jpeg",
  },
  {
    image: "./images/DTFB_Image8.jpeg",
  },
];
