import React from "react";
import "../../App.css";
import CardItemPageWeldQuality from "../CardItemPageWeldQuality";
import ImageSliderWeldQuality from "../ImageSlideWeldQuality";
import { SliderDataWeldQuality } from "../SliderDataWeldQuality";

function WeldQualityPage() {
  return (
    <div className="cardsbackground">
      {/* <img src="../../public/images/RS_Background_2.png" alt=""></img> */}
      <div className="cards">
        <h1>WELDING QUALITY</h1>
        <br></br>
        <div className="cards__container">
          <div className="cards_wrapper">
            <ul className="cards__items">
              <CardItemPageWeldQuality
                src="images/CoverPhoto_welder.jpeg"
                text="RS Contracting is committed to produce high quality work at a fast pass at a very competitive rate. The past 12 months we've welded over 2000 butts for the Tauhara Geothermal Power Station with no failures."
                texttwo=""
                path="/WeldQualityPage"
              />
            </ul>
          </div>
          <h2>PHOTO GALLERY OF WELDS</h2>
          <br></br>
          <ImageSliderWeldQuality slides={SliderDataWeldQuality} />
        </div>
      </div>
    </div>
  );
}

export default WeldQualityPage;
