export const SliderDataNgawha = [
  {
    image: "./images/Ngawha_Image1.jpeg",
  },

  {
    image: "./images/Ngawha_Image2.jpeg",
  },
  {
    image: "./images/Ngawha_Image3.jpeg",
  },
  {
    image: "./images/Ngawha_Image4.jpeg",
  },
  {
    image: "./images/Ngawha_Image5.jpeg",
  },
  {
    image: "./images/Ngawha_Image6.jpeg",
  },
  {
    image: "./images/Ngawha_Image7.jpeg",
  },
  {
    image: "./images/Ngawha_Image8.jpeg",
  },
  {
    image: "./images/Ngawha_Image9.jpeg",
  },
  {
    image: "./images/Ngawha_Image10.jpeg",
  },
  {
    image: "./images/Ngawha_Image12.jpeg",
  },
];
