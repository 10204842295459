import React from "react";
import { Link } from "react-router-dom";

function CardItemPageTauhara2022(props) {
  return (
    <>
      <li className="cards__carditempages">
        <Link className="cards__carditempages__link" to={props.path}>
          <figure
            className="cards__carditempages__pic-wraptauhara"
            data-category={props.label}
          >
            <img
              className="cards__carditempages__img"
              alt=""
              src={process.env.PUBLIC_URL + props.src}
            />
          </figure>
          <div>
            <div className="cards__carditempages__info">
              <h5 className="cards__carditempages__text">{props.text}</h5>
              <h5 className="cards__carditempages__texttwo">{props.texttwo}</h5>
              <br></br>
              <h5 className="cards__carditempages__textsix">{props.textsix}</h5>
              <h5 className="cards__carditempages__textseven">
                {props.textseven}
              </h5>
              <br></br>
              <h5 className="cards__carditempages__texteight">
                {props.texteight}
              </h5>
              <br></br>
              <br></br>
              <h5 className="cards__carditempages__textfivetitle">
                {props.textfivetitle}
              </h5>
            </div>
            <br></br>
          </div>
          <figure
            className="cards__carditempages__pic-wraptwo"
            data-category={props.label}
          >
            <img
              className="cards__carditempages__imgtwo"
              alt=""
              src={process.env.PUBLIC_URL + props.srctwo}
            />
          </figure>
          <div className="cards__carditempages__info">
            <h5 className="cards__carditempages__textfivetitletwo">
              {props.textfivetitletwo}
            </h5>
          </div>
          <br></br>
          <div>
            <figure
              className="cards__carditempages__pic-wrapthree"
              data-category={props.label}
            >
              <img
                className="cards__carditempages__imgthree"
                alt=""
                src={process.env.PUBLIC_URL + props.srcthree}
              />
            </figure>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItemPageTauhara2022;
