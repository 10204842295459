import React from "react";
import "../../App.css";
import CardItemPagesConventionCentre from "../CardItemPagesConventionCentre";
import ImageSliderICC from "../ImageSliderICC";
import { SliderDataICC } from "../SliderDataICC";

function StructualWeldingPage() {
  return (
    <div className="cardsbackground">
      {/* <img src="../../public/images/RS_Background_2.png" alt=""></img> */}
      <div className="cards">
        <h1>INTERNATIONAL CONVENTION CENTRE AUCKLAND</h1>
        <br></br>
        <br></br>
        <div className="cards__container">
          <div className="cards_wrapper">
            <ul className="cards__items">
              <CardItemPagesConventionCentre
                src="images/ICC_Image1.jpeg"
                text="RS Contracting was contracted to fabricate 300 x 900 diameter and 600 diameter pipe columns for the main structure of the International Convention Centre. Columns were fabricated in Culham Engineering's yard in Whangarei and shipped down to Auckland for installation."
                path="/PrevousProjectsPage"
              />
            </ul>

            <br></br>
            <h2>PHOTO GALLERY OF THE INTERNATIONAL CONVENTION CENTRE</h2>
            <br></br>
            <ImageSliderICC slides={SliderDataICC} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StructualWeldingPage;
