export const SliderDataWeldQuality = [
  {
    image: "./images/WeldQuality_Image1.jpeg",
  },

  {
    image: "./images/WeldQuality_Image2.jpeg",
  },
  {
    image: "./images/WeldQuality_Image3.jpeg",
  },
  {
    image: "./images/WeldQuality_Image4.jpeg",
  },
  {
    image: "./images/WeldQuality_Image5.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image9.jpeg",
  },
];
