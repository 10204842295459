import React from "react";
import "../../App.css";
import CardItemPageTaraSeparation from "../CardItemPageTaraSeparation";
import ImageSliderTaraSeparation from "../ImageSliderTaraSeparation";
import { SliderDataTaraSeparation } from "../SliderDataTaraSeparation";
import ImageSliderTaraSeparationTwo from "../ImageSliderTaraSeparationTwo";
import { SliderDataTaraSeparationTwo } from "../SliderDataTaraSeparationTwo";
import ImageSliderTauhara2022 from "../ImageSliderTauhara2022";
import { SliderDataTauhara2022 } from "../SliderDataTauhara2022";
import CardItemPageTauhara2022 from "../CardItemPageTauhara2022";

function TaraSeparationPlantPage() {
  return (
    <div className="cardsbackground">
      {/* <img src="../../public/images/RS_Background_2.png" alt=""></img> */}
      <div className="cards">
        <h1>TAUHARA SEPARATION PLANT</h1>
        <br></br>
        <div className="cards__container">
          <div className="cards_wrapper">
            <ul className="cards__items">
              <CardItemPageTaraSeparation
                src="images/TSP_Image3.jpeg"
                text="RS Contracting was approached by Culhams Engineering to help out spool up pipwork for the separation plant which consisted of pipe ranging from 400 diameter right up to 1300 diameter."
                textthree="This is by far the most complex job we have undertaken."
                textfour="For this contract we had to build a whole new spooling station as the older one was only designed for welding pipes smaller than 1050 diameter (it was also busy spooling up the Tauhara Steam Field for Warner Contruction)."
                textsix="We couldn't weld those big 1200, 1300 diameter elbows with the older model. We also made a few upgrades like a longer reaching subarc boom, this was necessary as we had to make a series of headerd and the weld position was constantly changing and we needed to adapt. We also added a automatic X and Y adjustment to make the welders job alot easier."
                textten="All pipework was done on site."
                srctwo="images/Tauhara2022_Image3568.png"
                texteleven="Spooling Station Number 2"
                srcthree="images/Tauhara2022_Image12479.png"
                path="/TaraSeparationPlantPage"
              />
            </ul>
            <br></br>
            <h2>PHOTO GALLERY OF TAUHARA SEPARATION PLANT</h2>
            <br></br>
            <ImageSliderTaraSeparation slides={SliderDataTaraSeparation} />
            <br></br>
            <h2>PHOTO GALLERY OF NEW SPOOLING STATION</h2>
            <br></br>
            <ImageSliderTaraSeparationTwo
              slides={SliderDataTaraSeparationTwo}
            />
            <h1>
              TAUHARA POWERSTATION<br></br>Reinjection & Production Pipelines
            </h1>
            <br></br>
            <div className="cards__container">
              <div className="cards_wrapper">
                <ul className="cards__items">
                  <CardItemPageTauhara2022
                    src="images/Tauhara2022_Image1.jpeg"
                    text="RS Contracting was approached by Warner Construction to help spool for 2x 500 diameter 15.88 all reinjection line and 2x 750 15.88 production lines. This consisted of Welling elbows on two 12 metre length of pipe, making 24 metre doubles manifolds and 3x strings of pipe upto 56 metres long to go under the off highway tunnel to the pipe corridor tie in point."
                    textsix="We welded close to 750 butts diameters ranging from 500 up to 800. At one point of the project RS Contracting had both spooling rigs of site welding close to 200 butts in one month."
                    texteight="Warner Contruction are well on track to finishing on time."
                    textfivetitle="Spooling Station Number One"
                    srctwo="images/Tauhara2022_Image3568.png"
                    textfivetitletwo="Spooling Station Number Two"
                    srcthree="images/Tauhara2022_Image12479.png"
                    path="/Tauhara2022Page"
                  />
                </ul>
                <br></br>
                <h2>PHOTO GALLERY OF TAUHARA POWERSTATION</h2>
                <br></br>
                <ImageSliderTauhara2022 slides={SliderDataTauhara2022} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaraSeparationPlantPage;
