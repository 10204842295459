import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import React from "react";
import { useState } from "react";
import { send } from "emailjs-com";

const Footer = () => {
  const [sender_name, set_sender_name] = useState("");
  const [sender_email, set_sender_email] = useState("");
  const [message, set_message] = useState("");

  const handleName = (e) => {
    set_sender_name(e.target.value);
  };
  const handleEmail = (e) => {
    set_sender_email(e.target.value);
  };
  const handlemessage = (e) => {
    set_message(e.target.value);
  };
  const sendMail = (e) => {
    e.preventDefault();
    send(
      "service_4dd8s0h",
      "template_key0n2a",
      { sender_name, sender_email, message },
      "I8O-OXmgMkuUadLOD"
    )
      .then((response) => {
        console.log(
          "Message sent successfully",
          response.status,
          response.text
        );
      })
      .catch((err) => {
        console.log("Failed", err);
      });
    set_sender_name("");
    set_sender_email("");
    set_message("");
  };

  return (
    <div className="footer-container">
      <div class="footer-links">
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <Link to="/ContactPage">CONTACT US</Link>
            <Link to="/ProjectsPage">PROJECTS</Link>
            <Link to="/PrevousProjectsPage">PREVOUS JOBS </Link>
          </div>
        </div>
      </div>

      <div class="footer-logo">
        <Link to="/" className="social-logo">
          <img
            src="images/RS Contracting Final Logo.png"
            alt=""
            height="80px"
            width="100px"
          ></img>
        </Link>
      </div>

      <small class="website-rights">
        © Copyright 2022 RS Contracting Ltd. All Rights Reserved.
      </small>
      <small class="website-design">
        <br></br>
        <br></br>
        Website designed and built by Mind Design.
      </small>
    </div>
  );
};

export default Footer;

// import React from "react";
// import "./Footer.css";
// import { Button } from "./Button";
// import { Link } from "react-router-dom";

// function Footer() {
//   return (
//     <div className="footer-container">
//       <div class="footer-links">
//         <div className="footer-link-wrapper">
//           <div class="footer-link-items"></div>
//         </div>
//       </div>

//       <section class="social-media">
//         <div class="social-media-wrap">
//           <small class="contact">Ph: 027 324 8863 | Taupo, New Zealand</small>
//           <br></br>
//           <div class="footer-logo">
//             <Link to="/" className="social-logo">
//               <img
//                 src="images/RS Contracting Final Logo.png"
//                 alt=""
//                 height="80px"
//                 width="100px"
//               ></img>
//             </Link>
//           </div>

//           <small class="website-rights">
//             © Copyright 2022 RS Contracting Ltd. All Rights Reserved
//           </small>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default Footer;
