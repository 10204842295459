import React from "react";
import { Link } from "react-router-dom";

function CardItemPagesTeMihi(props) {
  return (
    <>
      <li className="cards__carditempages">
        <Link className="cards__carditempages__link" to={props.path}>
          <figure
            className="cards__carditempages__pic-wrap"
            data-category={props.label}
          >
            <img
              className="cards__carditempages__imgtemihi"
              alt=""
              src={process.env.PUBLIC_URL + props.src}
            />
          </figure>
          <div>
            <div className="cards__carditempages__info">
              <h5 className="cards__carditempages__text">{props.text}</h5>
              <div></div>
              <h5 className="cards__carditempages__texttwo">{props.texttwo}</h5>
              <div></div>
              <br></br>
              <h5 className="cards__carditempages__textthree">
                {props.textthree}
              </h5>

              <div className="cards__carditempages__info">
                <h5 className="cards__carditempages__textfour">
                  {props.textfour}
                </h5>
              </div>
            </div>
            <figure
              className="cards__carditempages__pic-wraptwotemihi"
              data-category={props.label}
            >
              <img
                className="cards__carditempages__imgtwotemihi"
                alt=""
                src={process.env.PUBLIC_URL + props.srctwo}
              />
            </figure>
            <div className="cards__carditempages__info">
              <h5 className="cards__carditempages__textfivetitle">
                {props.textfivetitle}
              </h5>
              <br></br>
              <h5 className="cards__carditempages__textfive">
                {props.textfive}
              </h5>
            </div>
            <br></br>

            <figure
              className="cards__carditempages__pic-wrapthreetemihi"
              data-category={props.label}
            >
              <img
                className="cards__carditempages__imgthreetemihi"
                alt=""
                src={process.env.PUBLIC_URL + props.srcthree}
              />
            </figure>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItemPagesTeMihi;
