import React from "react";
import { Link } from "react-router-dom";

function CardItemPages(props) {
  return (
    <>
      <li className="cards__carditempages">
        <Link className="cards__carditempages__link" to={props.path}>
          <figure
            className="cards__carditempages__pic-wrap"
            data-category={props.label}
          >
            <img
              className="cards__carditempages__img"
              alt=""
              src={process.env.PUBLIC_URL + props.src}
            />
          </figure>
          <div>
            <div className="cards__carditempages__info">
              <h5 className="cards__carditempages__text">{props.text}</h5>

              <br></br>
              <h5 className="cards__carditempages__texttwozline">
                {props.texttwo}&emsp;&ensp;{props.textthree}&emsp;&ensp;
                {props.textfour}&emsp;&ensp;
              </h5>

              <h5 className="cards__carditempages__textthreezline">
                {props.textfive}&emsp;&ensp;{props.textsix}&emsp;&ensp;
                {props.textseven}&emsp;&ensp;
              </h5>
            </div>
            <br></br>
            {/* <div>
              <div className="cards__carditempages__infolist">
                <h5 className="cards__carditempages__list">{props.list}</h5>
                <h5 className="cards__carditempages__list">{props.list1}</h5>
                <h5 className="cards__carditempages__list">{props.list2}</h5>
                <h5 className="cards__carditempages__list">{props.list3}</h5>
                <h5 className="cards__carditempages__list">{props.list4}</h5>
                <h5 className="cards__carditempages__list">{props.list5}</h5>
              </div> */}
            <div></div>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItemPages;
