export const SliderDataTeMihi = [
  {
    image: "./images/TeMihi_Image1.jpg",
  },

  {
    image: "./images/TeMihi_Image2.jpg",
  },
  {
    image: "./images/TeMihi_Image3.jpg",
  },
  {
    image: "./images/TeMihi_Image4.jpg",
  },
  {
    image: "./images/TeMihi_Image5.jpg",
  },
  {
    image: "./images/TeMihi_Image6.jpg",
  },
  {
    image: "./images/TeMihi_Image7.jpg",
  },
  {
    image: "./images/TeMihi_Image8.jpg",
  },
  {
    image: "./images/TeMihi_Image9.jpg",
  },
];
