export const SliderDataTauhara2022 = [
  {
    image: "./images/Tauhara2022_Image1.jpeg",
  },

  {
    image: "./images/Tauhara2022_Image2.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image3.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image4.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image5.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image6.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image7.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image8.jpeg",
  },
  {
    image: "./images/Tauhara2022_Image9.jpeg",
  },
];
