import React from "react";
import "../../App.css";
import PrevousJobsCard from "../PrevousJobsCard";

function PrevousProjectsPage() {
  return (
    <div>
      <PrevousJobsCard />
    </div>
  );
}

export default PrevousProjectsPage;
