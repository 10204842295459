
export const SliderData = [
    {
        image: './images/Zline_Image3.jpeg'
    },

    {
        image: './images/Zline_Image2.jpeg'
    },
    {
        image: './images/Zline_Image1.jpeg'
    },
    {
        image: './images/Zline_Image5.jpeg'
    },

]