import React from "react";
import "./App.css";
import Navbar from "./Components/Navbar";
import Home from "./Components/pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProjectsPage from "./Components/pages/ProjectsPage";
import PrevousProjectsPage from "./Components/pages/PrevousProjectsPage";
import DowntownFerryBassinPage from "./Components/pages/DowntownFerryBassinPage";
import TaraSeparationPlantPage from "./Components/pages/TaraSeparationPlantPage";
import Tauhara2022Page from "./Components/pages/Tauhara2022Page";
import CrossCountryPage from "./Components/pages/CrossCountryPage";
import StructualWeldingPage from "./Components/pages/StructualWeldingPage";
import WeldQualityPage from "./Components/pages/WeldQualityPage";
import ContactPage from "./Components/pages/ContactPage";
import TSCPPage from "./Components/pages/TSCPPage";
import GalleryPage from "./Components/pages/GalleryPage";
import Footer from "./Components/Footer";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ProjectsPage" element={<ProjectsPage />} />?{" "}
          <Route
            path="/PrevousProjectsPage"
            element={<PrevousProjectsPage />}
          />
          <Route
            path="/DowntownFerryBassinPage"
            element={<DowntownFerryBassinPage />}
          />
          <Route path="/Tauhara2022Page" element={<Tauhara2022Page />} />
          <Route
            path="/TaraSeparationPlantPage"
            element={<TaraSeparationPlantPage />}
          />
          <Route path="/CrossCountryPage" element={<CrossCountryPage />} />
          <Route
            path="/StructualWeldingPage"
            element={<StructualWeldingPage />}
          />
          <Route path="/WeldQualityPage" element={<WeldQualityPage />} />
          <Route path="/ContactPage" element={<ContactPage />} />
          <Route path="/TSCPPage" element={<TSCPPage />} />
          <Route path="/GalleryPage" element={<GalleryPage />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
