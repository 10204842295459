import React from "react";
import "../../App.css";
import CardItemPageFerryBassin from "../CardItemPageFerryBassin";
import ImageSliderFerryBassin from "../ImageSliderFerryBassin";
import { SliderDataFerryBassin } from "../SliderDataFerryBassin";

function DowntownFerryBassinPage() {
  return (
    <div className="cardsbackground">
      {/* <img src="../../public/images/RS_Background_2.png" alt=""></img> */}
      <div className="cards">
        <h1>DOWNTOWN FERRY BASIN REDEVELOPMENT</h1>
        <br></br>
        <div className="cards__container">
          <div className="cards_wrapper">
            <ul className="cards__items">
              <CardItemPageFerryBassin
                src="images/DTFB_Image10.jpeg"
                text="RS Contracting was approached by SPI South Pacific Industrial to help out with the constraction of 7 floating pontoons. This took place in their work shop at Ruakaka for a period of 14 months."
                textfour="We had two subarc operators on site fitting up sections of pontoon outer skin welding the root run manually using migs with backing ceramics, once the root was in we then fitted a guide track that ran along side the seem and welded with a subarc tractor unit, welding upto 60 metres of 12mm plate a day."
                texteight="We also subarced the bollards together bolting them back to back to reduce distortion."
                textfivetitle="Pontoon"
                srcthree="images/DTFB_Image1910.png"
                path="/DowntownFerryBassinPage"
              />
            </ul>
            <br></br>
          </div>
          <h2>PHOTO GALLERY OF FERRY BASIN</h2>
          <ImageSliderFerryBassin slides={SliderDataFerryBassin} />
        </div>
      </div>
    </div>
  );
}

export default DowntownFerryBassinPage;
