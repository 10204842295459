import React from "react";
import { Link } from "react-router-dom";

function CardItemPageTaraSeparation(props) {
  return (
    <>
      <li className="cards__carditempages">
        <Link className="cards__carditempages__link" to={props.path}>
          <figure
            className="cards__carditempages__pic-wrap"
            data-category={props.label}
          >
            <img
              className="cards__carditempages__img"
              alt=""
              src={process.env.PUBLIC_URL + props.src}
            />
          </figure>
          <div>
            <div className="cards__carditempages__info">
              <h5 className="cards__carditempages__text">{props.text}</h5>
              <h5 className="cards__carditempages__texttwo">{props.texttwo}</h5>
              <br></br>
              <h5 className="cards__carditempages__textthree">
                {props.textthree}
              </h5>
              <br></br>
              <h5 className="cards__carditempages__textfour">
                {props.textfour}
              </h5>
              <br></br>
              <h5 className="cards__carditempages__textsix">{props.textsix}</h5>
              <h5 className="cards__carditempages__textseven">
                {props.textseven}
              </h5>

              <h5 className="cards__carditempages__texteight">
                {props.texteight}
              </h5>
              <h5 className="cards__carditempages__textnine">
                {props.textnine}
              </h5>
              <br></br>
              <h5 className="cards__carditempages__textten">{props.textten}</h5>
              <br></br>
            </div>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItemPageTaraSeparation;
