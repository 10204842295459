import React, { useState, useEffect } from "react";
import "../App.css";
import { Button } from "./Button";
import "./MainPage.css";
import { Link } from "react-router-dom";

function MainPage() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  return (
    <div className="hero-container">
      {/* <video src='/images/TiasTacosCoverPic7.jpg  /> */}

      <h1>SPECIALISING IN:</h1>
      <br></br>
      <Link
        to="/Tauhara2022Page"
        style={{ textDecoration: "none" }}
        className="hero-links"
        onClick={closeMobileMenu}
      >
        <p>CURRENTLY WORKING ON</p>{" "}
      </Link>
      <Link
        to="/CrossCountryPage"
        style={{ textDecoration: "none" }}
        className="hero-links"
        onClick={closeMobileMenu}
      >
        <p>CROSS COUNTRY PIPELINES</p>{" "}
      </Link>
      <Link
        to="/TaraSeparationPlantPage"
        style={{ textDecoration: "none" }}
        className="hero-links"
        onClick={closeMobileMenu}
      >
        <p>PIPELINE SPOOLING</p>{" "}
      </Link>
      <Link
        to="/TSCPPage"
        style={{ textDecoration: "none" }}
        className="hero-links"
        onClick={closeMobileMenu}
      >
        <p>CASING WELDING PILING</p>{" "}
      </Link>
      <Link
        to="/ProjectsPage"
        style={{ textDecoration: "none" }}
        className="hero-links"
        onClick={closeMobileMenu}
      >
        <p>RIG WELDING</p>{" "}
      </Link>
      <Link
        to="/StructualWeldingPage"
        style={{ textDecoration: "none" }}
        className="hero-links"
        onClick={closeMobileMenu}
      >
        <p>STRUCTUAL WELDING</p>{" "}
      </Link>
      <Link
        to="/TaraSeparationPlantPage"
        style={{ textDecoration: "none" }}
        className="hero-links"
        onClick={closeMobileMenu}
      >
        <p>SEPARATION STATIONS</p>{" "}
      </Link>
      <Link
        to="/DowntownFerryBassinPage"
        style={{ textDecoration: "none" }}
        className="hero-links"
        onClick={closeMobileMenu}
      >
        <p>STRUCTUAL MARINE</p>{" "}
      </Link>
      <Link
        to="/WeldQualityPage"
        style={{ textDecoration: "none" }}
        className="hero-links"
        onClick={closeMobileMenu}
      >
        <p>WELD QUALITY</p>{" "}
      </Link>
    </div>
  );
}
export default MainPage;
