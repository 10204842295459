import React from "react";
import "../../App.css";
import CardItemPagesTeMihiTwo from "../CardItemPagesTeMihiTwo";

function CrossCountryPage() {
  return (
    <div className="cardsbackground">
      {/* <img src="../../public/images/RS_Background_2.png" alt=""></img> */}
      <div className="cards">
        <h1>Z LINE - CONTACT ENERGY</h1>
        <br></br>
        <br></br>
        <div className="cards__container">
          <div className="cards_wrapper">
            <ul className="cards__items">
              <CardItemPagesTeMihiTwo
                src="images/TeMihiPhoto.jpeg"
                text="Te Mihi steam field and pipe corridor Kawerau KA30 pipeline."
                path="/PrevousProjectsPage"
              />
            </ul>
          </div>
        </div>
        <br></br>
      </div>
    </div>
  );
}

export default CrossCountryPage;
