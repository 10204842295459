import React from 'react';
import'../../App.css';
import ProjectCards from '../ProjectCards';

function ProjectsPage () {
    return (
        <div>
          <ProjectCards /> 
          </div>
    );
}

export default ProjectsPage;