import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";

function Cards() {
  return (
    <div className="cardsbackground">
      <div className="cards">
        <div className="cards__container">
          <div className="cards_wrapper">
            <ul className="cards__items">
              <CardItem
                src="images/DTFB_Image9.jpeg"
                text="RS Contracting Ltd have a few projects on the go at the moment, one being the Tauhara Geothermal Powerstation"
                label="Projects"
                path="/ProjectsPage"
              />
              <br></br>
              <CardItem
                src="images/SecondPage_pipe.jpeg"
                text="From structual welding to pipe spooling, RS Contracting have completed a lot of jobs from far and inbetween. Check out the previous jobs we have done."
                label="Previous Jobs"
                path="/PrevousProjectsPage"
              />
              <br></br>
              <CardItem
                src="images/Tauhara2022_Image9.jpeg"
                text="RS Contracting Ltd have a great bunch of people working alongside and have made all the completed jobs possible."
                label="Team"
                path="/"
              />
            </ul>
            <ul className="cards__items">
              <CardItem
                src="images/DTFB_Image4.jpeg"
                text="Check out all the work done by RS Contracting by clicking here to check out the photos."
                label="Gallery"
                path="/"
              />
              <br></br>
              <CardItem
                src="images/ZLine_Image5.jpeg"
                text="....."
                label="History"
                path="/"
              />
              <br></br>
              <CardItem
                src="images/RS Contracting Final Logo.png"
                text="....."
                label="About Us"
                path="/"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
